import { transparentize } from 'polished';
import styled, { extensionsHook } from '../styled-components';
import { PropertyNameCell } from './fields-layout';
import { ShelfIcon } from './shelfs';
export const ClickablePropertyNameCell = styled(PropertyNameCell)(["cursor:pointer;", "{height:", ";width:", ";polygon{fill:", ";}}"], ShelfIcon, ({
  theme
}) => theme.schema.arrow.size, ({
  theme
}) => theme.schema.arrow.size, ({
  theme
}) => theme.schema.arrow.color);
export const FieldLabel = styled.span(["vertical-align:middle;font-size:0.929em;line-height:20px;"]);
export const TypePrefix = styled(FieldLabel)(["color:", ";"], props => transparentize(0.2, props.theme.schema.typeNameColor));
export const TypeName = styled(FieldLabel)(["color:", ";"], props => props.theme.schema.typeNameColor);
export const TypeTitle = styled(FieldLabel)(["color:", ";"], props => props.theme.schema.typeTitleColor);
export const TypeFormat = TypeName;
export const RequiredLabel = styled(FieldLabel.withComponent('div'))(["color:", ";font-size:", ";font-weight:normal;margin-left:20px;line-height:1;"], props => props.theme.schema.requireLabelColor, props => props.theme.schema.labelsTextSize);
export const RecursiveLabel = styled(FieldLabel)(["color:", ";font-size:13px;"], ({
  theme
}) => theme.colors.warning.main);
export const NullableLabel = styled(FieldLabel)(["color:#3195a6;font-size:13px;"]);
export const PatternLabel = styled(FieldLabel)(["color:#3195a6;&::before,&::after{content:'/';font-weight:bold;}"]);
export const ExampleValue = styled(FieldLabel)(["border-radius:2px;", ";& + &{margin-left:0;}", ";"], ({
  theme
}) => `
    background-color: ${transparentize(0.95, theme.colors.text.primary)};
    color: ${transparentize(0.1, theme.colors.text.primary)};

    margin: ${theme.spacing.unit}px;
    padding: 0 ${theme.spacing.unit}px;
    border: 1px solid ${transparentize(0.9, theme.colors.text.primary)};
}`, extensionsHook('ExampleValue'));
export const ConstraintItem = styled(FieldLabel)(["border-radius:2px;", ";& + &{margin-left:0;}", ";"], ({
  theme
}) => `
    background-color: ${transparentize(0.95, theme.colors.primary.light)};
    color: ${transparentize(0.1, theme.colors.primary.main)};

    margin: 0 ${theme.spacing.unit}px;
    padding: 0 ${theme.spacing.unit}px;
    border: 1px solid ${transparentize(0.9, theme.colors.primary.main)};
}`, extensionsHook('ConstraintItem'));